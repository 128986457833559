import React, { useState, useEffect } from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import axios from 'axios';
import styles from './css/manager.module.css';

import { db } from './firebaseSetup';
import { collection, doc, getDocs, where, query } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from "firebase/auth";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './css/toastt.css';

import notificationBtn from './images/icons/notification.svg'
import settingBtn from './images/icons/setting-icon.svg'
import chartIcon from './images/icons/Chart.svg'
import chatIcon from './images/icons/Chat.svg'
import workIcon from './images/icons/Work.svg'
import users3 from './images/icons/users3.svg'
import addUserIcon from './images/icons/Add User.svg'
import profileIcon from './images/icons/Profile.svg'
import calendarIcon from './images/icons/Calendar.svg'
import blackImage from './images/blackImage.png'
import graphImage from './images/graph_image.png'
import circleImage from './images/circle.svg'
import { url } from '../constData'

import { Link, useNavigate } from 'react-router-dom';

function managerPage() {

    const userData = JSON.parse(localStorage.getItem("userDataNew"));

    const [urlList, setUrlList] = useState([])

    const [show, setShow] = useState(false);
    const [showM, setShowM] = useState(false);
    const [showEmpty, setShowEmpty] = useState(false);
    const [open, setOpen] = useState(false);
    const [managerList, setmanagerList] = useState(null);
    const [fname, setFname] = useState("");
    const [numberSelected, setNumberSelected] = useState("");
    const [emailSelected, setEmailSelected] = useState("");
    const [password, setPassword] = useState("");

    const navigate = useNavigate();

    const fetchData = async () => {
        setOpen(true);
        try {
            const resp = await axios.get(`${url}/api/studio/getAllManagers`, {
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    "x-studio-token": userData.token,
                }
            });
            if (resp.data.manager.length > 0) {
                sessionStorage.setItem("managerList", resp.data.manager);
                setmanagerList(resp.data.manager);
                setShowM(true);
                setShow(true);
            }
            else {
                if (resp.data.manager.length === 0) {
                    setmanagerList([]);
                }
                sessionStorage.removeItem("managerList");
                setShowM(true);
                setShow(true);
            }
            setOpen(false);
        } catch (error) {
            console.log(`the error is here -> ${error.response}`);
            setOpen(false);
        }
    }

    const sendURL = async (userId, index) => {
        setOpen(true);
        try {
            const resp = await axios.post(`${url}/api/manager/sendURL`, {
                url: urlList[index],
                urlDate: new Date().toLocaleString(),
                userId: userId,
            }, {
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                }
            });
            setUrlList([]);
            setOpen(false);
        } catch (error) {
            console.log(`here is the error => ${error}`);
            setUrlList([]);
            setOpen(false);
        }
    }

    const deleteManager = async (userId) => {
        try {
            const resp = await axios.post(`${url}/api/studio/deleteManager`, {
                userId: userId,
            }, {
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    "x-studio-token": userData.token,
                }
            });
            sessionStorage.setItem("managerList", resp.data.manager);
            setmanagerList(resp.data.manager);
            if (resp.data.manager.length > 0) {
                setShowM(true);
                setShow(true);
            }
            setOpen(false);
        } catch (error) {
            console.log(`here is the error of delete manager -> ${error}`);
            setOpen(false);
        }
    }

    const handelInput = async (event, index) => {
        const newValue = [...urlList];
        newValue[index] = event.target.value;
        setUrlList(newValue);
    }




    useEffect(() => {
        fetchData();


    }, [])



    const changeDisplay = () => {
        setShow(!show);
        setShowM(!showM);
    }



    const uploadData = async () => {
        setOpen(true);
        try {
            if (fname === "", numberSelected === "", emailSelected === "", password === "") {
                toast.error("Fill all the fields", {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'toast-message',
                });
                setOpen(false);
            }
            else {
                const response = await axios.post(`${url}/api/manager/signup`, {
                    fname: fname,
                    number: numberSelected,
                    email: emailSelected,
                    password: password,
                    userId: userData._id
                }, {
                    headers: {
                        "Content-Type": "application/json; charset=UTF-8",
                    },
                });
                
                
                const resp = await axios.get(`${url}/api/studio/getAllManagers`, {
                    headers: {
                        "Content-Type": "application/json; charset=UTF-8",
                        "x-studio-token": userData.token,
                    }
                });
                if (resp.data.manager.length > 0) {
                    setmanagerList(resp.data.manager);
                    setShowM(true);
                    setShow(true);
                }
                setOpen(false);
                    
                
            }



        } catch (error) {
            
            // console.log(`here is the error => ${error}`);
            // alert("Something went wrong");
            toast.error(error.response.data.msg)
            setOpen(false);
        }
    }

    const clearAllData = async () => {
	    setOpen(true);
        try {
	    	localStorage.clear();
                sessionStorage.clear();
                navigate("/");
            const resp = await axios.post(`${url}/api/studio/logout`, {
                noData: "aa"
            }, {
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    "x-studio-token": localStorage.getItem("x-studio-token"),
                }
            });
            if (resp.status === 200) {
                localStorage.clear();
                sessionStorage.clear();
                setOpen(false);
                navigate("/");

            } else {
                setOpen(false);
            }

        } catch (error) {
            console.log(`herere is errorror -> ${error.response}`);
            setOpen(false);
        }

    }

    if (managerList === null) {
        return (
            <>
                <ToastContainer />
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div className={styles.main_yellow}>
                    <div className={styles.left_side_menu}>
                        <div className={styles.studio_title}>
                            <img src={userData.profilePic === "" ? blackImage : userData.profilePic} alt="profile-pic" />
                            <span className={styles.studio_name}>Studio</span>
                        </div>
                        <div className={styles.main_menu}>
                            <Link to="/dashboard" className={styles.dashboard_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={chartIcon} alt="icons" /><span> Dashboard</span>
                                </div>
                            </Link>
                            <Link to="/inbox" className={styles.inbox_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={chatIcon} alt="icons" /><span> Inbox</span>
                                </div>
                            </Link>
                            <Link to="/job" className={styles.jobs_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={workIcon} alt="icons" /><span> Jobs</span>
                                </div>
                            </Link>
                            <Link to="/connection" className={styles.connection_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={users3} alt="icons" /><span> Connections</span>
                                </div>
                            </Link>
                            {/* <Link to="/invite" className={styles.invite_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={addUserIcon} alt="icons" /><span> Invite</span>
                                </div>
                            </Link> */}
                            <Link to="/profile" className={styles.profile_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={profileIcon} alt="icons" /><span> Profile</span>
                                </div>
                            </Link>
                            <Link to="/interview" className={styles.schedule_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={calendarIcon} alt="icons" /><span> Schedule
                                        Interview</span>
                                </div>
                            </Link>
                            <Link to="/manager-page" className={styles.manager_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={addUserIcon} alt="icons" /><span>Manager Section</span>
                                </div>
                            </Link>
                            <Link to='/' className={styles.logout_selected}>
                                <div className={styles.main_menu_content} onClick={clearAllData}>
                                    <img src={workIcon} alt="icons" /><span>Logout</span>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={styles.main_white}>

                </div>

            </>
        )
    }
    else {
        return (
            <>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <ToastContainer />
                <div className={styles.main_yellow}>
                    <div className={styles.left_side_menu}>
                        <div className={styles.studio_title}>
                            <img src={userData.profilePic === "" ? blackImage : userData.profilePic} alt="profile-pic" />
                            <span className={styles.studio_name}>Studio</span>
                        </div>
                        <div className={styles.main_menu}>
                            <Link to="/dashboard" className={styles.dashboard_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={chartIcon} alt="icons" /><span> Dashboard</span>
                                </div>
                            </Link>
                            <Link to="/inbox" className={styles.inbox_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={chatIcon} alt="icons" /><span> Inbox</span>
                                </div>
                            </Link>
                            <Link to="/job" className={styles.jobs_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={workIcon} alt="icons" /><span> Jobs</span>
                                </div>
                            </Link>
                            <Link to="/connection" className={styles.connection_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={users3} alt="icons" /><span> Connections</span>
                                </div>
                            </Link>
                            {/* <Link to="/invite" className={styles.invite_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={addUserIcon} alt="icons" /><span> Invite</span>
                                </div>
                            </Link> */}
                            <Link to="/profile" className={styles.profile_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={profileIcon} alt="icons" /><span> Profile</span>
                                </div>
                            </Link>
                            <Link to="/interview" className={styles.schedule_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={calendarIcon} alt="icons" /><span> Schedule
                                        Interview</span>
                                </div>
                            </Link>
                            <Link to="/manager-page" className={styles.manager_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={addUserIcon} alt="icons" /><span>Manager Section</span>
                                </div>
                            </Link>
                            <Link to='/' className={styles.logout_selected}>
                                <div className={styles.main_menu_content} onClick={clearAllData}>
                                    <img src={workIcon} alt="icons" /><span>Logout</span>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={styles.main_white}>
                    <div className={styles.addSection}>
                        <button className={styles.addManager} style={{ display: showM ? "block" : "none" }} onClick={changeDisplay}>Add</button>
                    </div>
                    <div className={styles.name} style={{ display: managerList.length === 0 ? show ? "flex" : "none" : "none" }}>
                        <h1>
                            Manager/Sub-manager
                        </h1>
                        <span>You don't have any Manager/Sub-manager</span>
                    </div>
                    <div className={styles.createManagerForm} style={{ display: show ? "none" : "flex" }}>
                        <div className={styles.ccd}>
                            <label htmlFor="mname">Name</label>
                            <input type="text" id="mname" value={fname} onChange={e => setFname(e.target.value)} placeholder='Enter manager name' />
                        </div>
                        <div className={styles.ccd}>
                            <label htmlFor="mnumber">Number</label>
                            <input type="text" id="mnumber" value={numberSelected} onChange={e => setNumberSelected(e.target.value)} placeholder='Enter manager phone no.' />
                        </div>
                        <div className={styles.ccd}>
                            <label htmlFor="memail">Email</label>
                            <input type="text" id="memail" value={emailSelected} onChange={e => setEmailSelected(e.target.value)} placeholder='Enter manager email' />
                        </div>
                        <div className={styles.ccd}>
                            <label htmlFor="mpassword">Password</label>
                            <input type="password" id="mpassword" value={password} onChange={e => setPassword(e.target.value)} placeholder='Enter password' />
                        </div>

                        <div className={styles.doneArea}>
                            <button onClick={changeDisplay}>Cancel</button>
                            <button onClick={uploadData}>Done</button>
                        </div>

                    </div>

                    <div className={styles.managerTitleSection} style={{ display: managerList.length === 0 ? "none" : show ? "flex" : "none" }}>
                        <h3>Managers/Sub-managers</h3>
                    </div>
                    <div className={styles.managersList} style={{ display: managerList === null ? "none" : show ? "flex" : "none" }}>
                        {
                            managerList.map((manager, index) =>
                                <div className={styles.managerBox} key={index}>
                                    <img src={blackImage} alt="profilePic" />
                                    <span>{manager.fname}</span>
                                    <span>Email: {manager.email}</span>
                                    <span>Phone no.: {manager.number}</span>
                                    <div className={styles.sendLinkInput}>
                                        <input type="text" placeholder='Send meeting link' value={urlList[index]} onChange={(event) => handelInput(event, index)} />
                                        <button onClick={() => { sendURL(manager._id, index) }}>&hearts;</button>
                                    </div>
                                    <button className={styles.managerBoxButton} onClick={() => deleteManager(manager._id)}>Delete</button>
                                </div>
                            )
                        }


                    </div>
                </div>

            </>
        )
    }



}

export default managerPage
