import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import axios from "axios";
import styles from "./css/editProfile.module.css";
// import './css/inbox-resp.css'

import { collection, doc, getDoc, getDocs, where, query, addDoc, orderBy, updateDoc } from 'firebase/firestore';
import { auth, db } from "./firebaseSetup";
import blackImage from "./images/blackImage.png";
import chartIcon from "./images/icons/Chart.svg";
import chatIcon from "./images/icons/Chat.svg";
import workIcon from "./images/icons/Work.svg";
import users3 from "./images/icons/users3.svg";
import addUserIcon from "./images/icons/Add User.svg";
import profileIcon from "./images/icons/Profile.svg";
import calendarIcon from "./images/icons/Calendar.svg";
import crossIcon from "./images/icons/crs.svg";
import rightIcon from "./images/icons/rightTick.svg";
import f3 from "./images/f3.jpg";
import f1 from "./images/f1.jpg";
import f2 from "./images/f2.jpg";
import f4 from "./images/f4.jpg";
import f5 from "./images/f5.jpg";
import f6 from "./images/f6.jpg";
import attach from "./images/icons/attach.svg";
import { url } from "../constData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./css/toastt.css";
import { storage } from "./firebaseSetup";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

function EditProfile() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const userData = JSON.parse(localStorage.getItem("allUserData"));
 

  const handelEdit = () => {
    sessionStorage.setItem("updatedProfile", "no");
    navigate("/profile");
  };

  const [fname, setFname] = useState(userData.fname);
  const [location, setLocation] = useState(userData.location);
  const [profileURL, setProfileURL] = useState(userData.profileUrl);
  const [projectDesc, setProjectDesc] = useState(userData.projectDesc);
  const [profile, setProfile] = useState(userData.profilePic);
  const [imageUrl, setImageUrl] = useState(userData.profilePic);


  const updateProfile = async (e) => {
    e.preventDefault();
    try{
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = () => {
        setProfile(reader.result);
      };
      const file = e.target.files[0];       
      setOpen(true);
        const userIID = JSON.parse(localStorage.getItem("userDataNew"))._id;
        const datee = new Date().toISOString().split("T")[0];
        const storageRef = ref(
          storage,
          `images/${userIID}/${userIID}_${datee}_${file.name}`
        );
        await uploadBytesResumable(storageRef, file);
        const imageUrll = await getDownloadURL(storageRef);
        
        const imageurl = await axios.post(
          `${url}/api/upload/studio/profilePic`,
          {
            profilePicUrl: imageUrll,
          },
          {
            headers: {
              "Content-Type": "application/json; charset=UTF-8",
              "x-studio-token": userData.token.toString(),
            },
          }
        );        
        setProfile(imageUrll); 
        if(imageurl.status === 200){
          localStorage.setItem("userData", JSON.stringify(imageurl.data));
          await updateDoc(doc(db,"users",userIID),{
            profilePic: imageUrll,
        });   
          setOpen(false);
        }  
    }
    catch(error){
      setOpen(false);
      toast.error(`${error.response.data.msg}`);
    }
  };

  const updateDetails = async () => {
    setOpen(true);
    try {
      
      const response = await axios.post(
        `${url}/api/studio/updateNameLoc`,
        {
          fname: fname,
          location: location,
        },
        {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "x-studio-token": userData.token.toString(),
          },
        }
      );
      const rresp = await axios.post(
        `${url}/api/studio/profileURL`,
        {
          profileUrl: profileURL,
        },
        {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "x-studio-token": userData.token.toString(),
          },
        }
      );
      const resp = await axios.post(
        `${url}/api/studio/updateProjectDesc`,
        {
          projectDesc: projectDesc,
        },
        {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "x-studio-token": userData.token.toString(),
          },
        }
      );
      sessionStorage.setItem("updatedProfile", "yes");
      setFname(response.data.fname);
      setLocation(response.data.location);
      setProfileURL(rresp.data.profileUrl);
      setProjectDesc(resp.data.projectDesc);
      setOpen(false);
      navigate("/profile");
    } catch (error) {
      setOpen(false);
      
      //   toast.error(`${error.response.data.msg}`, {
      //     position: toast.POSITION.TOP_RIGHT,
      //     className: "toast-message",
      //   });
    }
  };

  const clearAllData = async () => {
    setOpen(true);
    try {
      const resp = await axios.post(
        `${url}/api/studio/logout`,
        {
          noData: "aa",
        },
        {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "x-studio-token": localStorage.getItem("x-studio-token"),
          },
        }
      );
      if (resp.status === 200) {
        localStorage.clear();
        sessionStorage.clear();
        setOpen(false);
        navigate("/");
      } else {
        setOpen(false);
      }
    } catch (error) {
      console.log(`herere is errorror -> ${error.response}`);
      toast.error(error);
      setOpen(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={styles.main_yellow}>
        <div className={styles.left_side_menu}>
          <div className={styles.studio_title}>
            <img
              src={
                userData.profilePic === "" ? blackImage : userData.profilePic
              }
              alt="profile-pic"
            />
            <span className={styles.studio_name}>Studio</span>
          </div>
          <div className={styles.main_menu}>
            <Link to="/dashboard" className={styles.dashboard_selected}>
              <div className={styles.main_menu_content}>
                <img src={chartIcon} alt="icons" />
                <span> Dashboard</span>
              </div>
            </Link>
            <Link to="/inbox" className={styles.inbox_selected}>
              <div className={styles.main_menu_content}>
                <img src={chatIcon} alt="icons" />
                <span> Inbox</span>
              </div>
            </Link>
            <Link to="/job" className={styles.jobs_selected}>
              <div className={styles.main_menu_content}>
                <img src={workIcon} alt="icons" />
                <span> Jobs</span>
              </div>
            </Link>
            <Link to="/connection" className={styles.connection_selected}>
              <div className={styles.main_menu_content}>
                <img src={users3} alt="icons" />
                <span> Connections</span>
              </div>
            </Link>
            {/* <Link to="/invite" className={styles.invite_selected}>
              <div className={styles.main_menu_content}>
                <img src={addUserIcon} alt="icons" />
                <span> Invite</span>
              </div>
            </Link> */}
            <Link to="/profile" className={styles.profile_selected}>
              <div className={styles.main_menu_content}>
                <img src={profileIcon} alt="icons" />
                <span> Profile</span>
              </div>
            </Link>
            <Link to="/interview" className={styles.schedule_selected}>
              <div className={styles.main_menu_content}>
                <img src={calendarIcon} alt="icons" />
                <span> Schedule Interview</span>
              </div>
            </Link>
            <Link to="/manager-page" className={styles.manager_selected}>
              <div className={styles.main_menu_content}>
                <img src={addUserIcon} alt="icons" />
                <span>Manager Section</span>
              </div>
            </Link>
            <Link to="/" className={styles.logout_selected}>
              <div className={styles.main_menu_content} onClick={clearAllData}>
                <img src={workIcon} alt="icons" />
                <span>Logout</span>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className={styles.main_white}>
        <div className={styles.editTitle}>
          <div className={styles.crossWidthEdit}>
            <img src={crossIcon} onClick={handelEdit} alt="crossIcon" />

            <span>Edit Profile</span>
          </div>
          <img src={rightIcon} onClick={updateDetails} alt="rightIcon" />
        </div>
        <div className={styles.photoSection}>
          <img
            src={profile === "" ? blackImage : profile }
            alt="profilePic"
          />
          <span>
            <label
              htmlFor="image-input"
              style={{
                position: "absolute",
                cursor: "pointer",
                marginTop: "0vh",
                marginLeft: "-3vw",
                height: "5.2vh",
                width: "9vw",
                backgroundPosition: "center",
              }}
            >
              Edit Photo
              <input
                type="file"
                id="image-input"
                accept="image/*"
                onChange={updateProfile}                
                style={{ display: "none" }}
              />
            </label>
          </span>
        </div>

        <div className={styles.formArea}>
          <input
            type="text"
            placeholder="Name"
            value={fname}
            onChange={(e) => setFname(e.target.value)}
          />
          <input
            type="text"
            placeholder="Location"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
          <input
            type="text"
            placeholder="Enter any Link"
            value={profileURL}
            onChange={(e) => setProfileURL(e.target.value)}
          />
          <input
            type="text"
            placeholder="Project Description"
            value={projectDesc}
            onChange={(e) => setProjectDesc(e.target.value)}
          />
          <div className={styles.sp}>
            <span>Word limit 1000</span>
            <span className={styles.spp}>*</span>
          </div>
        </div>
      </div>
    </>
  );
}
// }

export default EditProfile;
