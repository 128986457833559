import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./css/signup.module.css";
import passwordGif from "./images/stPassword.png";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import axios from "axios";
import Modal from "@mui/material/Modal";
import OtpInput from "react-otp-input";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./css/toastt.css";

import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import {
  collection,
  doc,
  getDocs,
  where,
  query,
  setDoc,
} from "firebase/firestore";

import { auth, db } from "./firebaseSetup";

import { url } from "../constData";

function signinPage() {
  const [fname, setFname] = useState("");
  const [numberSelected, setNumberSelected] = useState("");
  const [emailSelected, setEmailSelected] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [otpVerifyModal, setOtpVerifyModal] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);

  const [token, setToken] = useState("");
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const userCollectionRef = collection(db, "users");
  const [selectedCountryCode, setSelectedCountryCode] = useState('+91');

  const fetchData = async (event) => {
    event.preventDefault();
    setOpen(true);
    if(fname === "" || numberSelected === "" || emailSelected === "" || password === ""){
      toast.error("Please fill all the fields!");
      setOpen(false);
      return;
    }
    if(selectedCountryCode === '+91' && numberSelected.length !== 10){
      toast.error("Please enter a valid phone number!");
      setOpen(false);
      return;
    }
    if(password.length < 8){
      toast.error("Password must be atleast 8 characters long!");
      setOpen(false);
      return;
    }
    
    try {
      const response = await axios.post(
        `${url}/api/studio/web/signup`,
        {
          fname: fname,
          countryCode: selectedCountryCode,
          number: numberSelected,
          email: emailSelected,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
          },
        }
      );

      if (response.status === 200) {
        setOpen(false);
        setOtpVerifyModal(true);
      } else if (response.status === 400) {
        toast.success("Studio Account created!", {
          position: toast.POSITION.TOP_RIGHT,
          className: "toast-message",
        });
      } else {
        setOpen(false);
      }
    } catch (error) {
      if (error.response.status === 400) {
        toast.error(`${error.response.data.msg}`, {
          position: toast.POSITION.TOP_RIGHT,
          className: "toast-message",
        });
      } else {
        toast.error(`${error.response.data.msg}`, {
          position: toast.POSITION.TOP_RIGHT,
          className: "toast-message",
        });
      }
      setOpen(false);
    }
  };

  const verifyOtp = async (event) => {
    event.preventDefault();
    setOpen(true);
    try {
      const response = await axios.post(
        `${url}/api/studio/web/verify-otp`,
        {
          fname: fname,
          countryCode: selectedCountryCode,
          number: numberSelected,
          email: emailSelected,
          password: password,
          otp: otp,
        },
        {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
          },
        }
      );
      if (response.status === 200) {
        if (response.data.created === "create") {
          createUserWithEmailAndPassword(
            auth,
            emailSelected,
            `${emailSelected.trim()}password`
          ).then(async (res) => {
            await setDoc(doc(db, "users", `${response.data._id}`), {
              fullName: fname,
              email: emailSelected,
              groups: [],
              notification: [
                "Welcome to Studio Dashboard! We're excited to have you here and can't wait to see what you create.",
              ],
              profilePic:
                "https://firebasestorage.googleapis.com/v0/b/auditionportal-2597e.appspot.com/o/ProfilePlaceholder.png?alt=media&token=3ea3bc21-8342-46c6-b7c7-94dd5c380a75",
              uid: response.data._id,
            });

            sessionStorage.setItem("created", "yes");
            setOpen(false);
            setOtpVerifyModal(false);
            setOtpVerified(true);
          });
        } else if (response.data.created === "exist") {
          signInWithEmailAndPassword(
            auth,
            emailSelected,
            `${emailSelected.trim()}password`
          ).then(async (res) => {
            await setDoc(doc(db, "users", `${response.data._id}`), {
              fullName: fname,
              email: emailSelected,
              groups: [],
              notification: [
                "Welcome to Studio Dashboard! We're excited to have you here and can't wait to see what you create.",
              ],
              profilePic: "",
              uid: response.data._id,
            });

            sessionStorage.setItem("created", "yes");
            setOpen(false);
            setOtpVerifyModal(false);
            setOtpVerified(true);
          });
        } else {
          toast.error("Something went wrong!", {
            position: toast.POSITION.TOP_RIGHT,
            className: "toast-message",
          });
        }
      } else if (response.status === 400) {
        toast.success("Studio Account created!", {
          position: toast.POSITION.TOP_RIGHT,
          className: "toast-message",
        });
      } else {
        setOpen(false);
      }
    } catch (error) {
      if (error.response.status === 400) {
        toast.error(`${error.response.data.msg}`, {
          position: toast.POSITION.TOP_RIGHT,
          className: "toast-message",
        });
      } else {
        toast.error(`${error.response.data.msg}`, {
          position: toast.POSITION.TOP_RIGHT,
          className: "toast-message",
        });
      }
      setOpen(false);
    }
  };

  if (otpVerified) {
    navigate("/");
  }

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 100 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 9 }}
        open={otpVerifyModal}
      ></Backdrop>

      <Modal sx={{ zIndex: (theme) => theme.zIndex.drawer + 10 }} open={otpVerifyModal}>
        <div className={styles.otpVerifyModal}>
          <h1 className={styles.login_text}>Verify OTP</h1>
          <div className={styles.username_div}>
            <form className={styles.otpForm} id="form2" onSubmit={verifyOtp}>
              <label>Enter verification code</label>

              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderSeparator={
                  <span className={styles.otpInputSeprater}>-</span>
                }
                renderInput={(props) => (
                  <input
                    {...props}
                    className={styles.otpInput}
                    placeholder="0"
                  />
                )}
                className={styles.otpInput}
              />

              {/* <input
                  className={styles.signupInput}
                  type="text"
                  placeholder="Enter OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                /> */}
              <button
                type="submit"
                form="form2"
                className={styles.verify_button}
              >
                Verify
              </button>
            </form>
          </div>
        </div>
      </Modal>

      <ToastContainer />
      <div className={styles.white_div}></div>
      <div className={styles.yellow_div}>
        <h1 className={styles.login_text}>Sign up</h1>
        <div className={styles.username_div}>
          <form className={styles.signupForm} id="form1" onSubmit={fetchData}>
            <label className={styles.signupLabel}>Studio Name</label>

            <input
              className={styles.signupInput}
              type="text"
              placeholder="Enter your name"
              value={fname}
              onChange={(e) => setFname(e.target.value)}
            />

            <label className={styles.signupLabel}>Phone no.</label>
            <div className={styles.number_div}>
              <select className={styles.country_code}>
                <option value="+91">India (+91)</option>
                <option value="+971">UAE (+971)</option>
              </select>
            <input
              className={styles.number_input}
              type="text"
              placeholder="Enter your phone no."
              value={numberSelected}
              onChange={(e) => setNumberSelected(e.target.value)}
            />
            </div>

            <label className={styles.signupLabel}>Email Id</label>

            <input
              className={styles.signupInput}
              type="text"
              placeholder="Enter your email id"
              value={emailSelected}
              onChange={(e) => setEmailSelected(e.target.value)}
            />

            <label className={styles.signupLabel}>Password</label>

            <input
              className={styles.signupInput}
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </form>
          <div className={styles.signup_button_area}>
            {/* <Link className={styles.signup_aTag} to="/dashboard"> */}
            <button
              type="submit"
              form="form1"
              className={styles.signup_buttonn}
            >
              Sign up
            </button>
            {/* </Link> */}
          </div>
        </div>
      </div>
      <div>
        <img
          className={styles.login_gif}
          src={passwordGif}
          alt="password-gif"
        />
      </div>
    </>
  );
}

export default signinPage;
