import React, { useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import styles from './css/postJob.module.css';

import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

import chartIcon from './images/icons/Chart.svg'
import chatIcon from './images/icons/Chat.svg'
import workIcon from './images/icons/Work.svg'
import users3 from './images/icons/users3.svg'
import addUserIcon from './images/icons/Add User.svg'
import profileIcon from './images/icons/Profile.svg'
import calendarIcon from './images/icons/Calendar.svg'
import notificationBtn from './images/icons/notification.svg'
import settingBtn from './images/icons/setting-icon.svg'
import filterBtn from "./images/icons/filter-icon.svg"
import downArrow from "./images/icons/down-arrow.svg";
import uploadIcon from "./images/Vector.svg";
import blackImage from './images/blackImage.png'
import f3 from './images/f3.jpg'
import f1 from './images/f1.jpg'
import f2 from './images/f2.jpg'
import f4 from './images/f4.jpg'
import f5 from './images/f5.jpg'
import f6 from './images/f6.jpg'
import attach from './images/icons/attach.svg'
import { storage } from './firebaseSetup';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { db } from './firebaseSetup';
import { collection, getDoc, doc, updateDoc, arrayUnion } from 'firebase/firestore';
import { upload } from '@testing-library/user-event/dist/upload';
import { url } from '../constData'
import axios from 'axios';

function postJobPage() {
    const userCollectionRef = collection(db, "users")
    const userFullData = JSON.parse(localStorage.getItem("userData"));
    const [studioName, setStudioName] = useState(userFullData.fname);
    const [jobType, setJobType] = useState("Actor");
    const [socialLink, setSocialLink] = useState("");
    const [desc, setDesc] = useState("");
    const [productionDetail, setProductionDetail] = useState("");
    const [dateSelected, setDateSelected] = useState("");
    const [lastDateSelected, setLastDateSelected] = useState("");
    const [locationSelected, setLocationSelected] = useState("");
    const [numberSelected, setNumberSelected] = useState("");
    const [keyDetails, setKeyDetails] = useState("");
    const [wc1, setWc1] = useState(0);
    const [wc2, setWc2] = useState(0);
    const [statuss, setStatuss] = useState(null);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const inputFile = useRef(null);
    const inputFile1 = useRef(null);

    const handelClick = () => {
        inputFile.current.click();
    }
    const handelClick1 = () => {
        inputFile1.current.click();
    }

    const handelDescWordLimit = (event) => {
        const newText = event.target.value;
        const wordCount = newText.split(" ").length
        setWc1(wordCount);
        if (wordCount <= 5) {
            setDesc(newText);
        }
        else {
            setDesc(newText.split(" ").slice(0, 100).join(" "));
        }
    }

    const sendInAppNotification = async (studioId,userId, message) => {
        console.log(userId, message);
        try {
            const currentDatetime = new Date();
            const formattedDatetime = currentDatetime.toISOString().slice(0, 19).replace("T", " ");
          const userDocRef = doc(db, "users", userId);
          const studioDocRef = doc(db, "users", studioId);
          const profilePic = await getDoc(studioDocRef,"profilePic");
          await updateDoc(userDocRef, {
            notification: arrayUnion(`${message}__${profilePic.data().profilePic}_${formattedDatetime}`),
          }); 
        } catch (error) {
          console.log(error);
        }
      };
    
      const sendPushNotification = async (title,studioId,userId, message,jobType) => {
          try {
          const userDocRef = doc(db, "users", userId);
          const studioDocRef = doc(db, "users", studioId);
          const profilePic = await getDoc(studioDocRef,"profilePic");
          const userFcmToken = await getDoc(userDocRef,"fCMToken");
    
          let data = {
            to: userFcmToken.data().fCMToken,
            priority: "high",
            notification: {
              title: title,
              body: message,
              alert: true,
              sound : "default",
            },
            data: {
                type: 'new job',
                category:jobType
              }
            };

            
    
          const resp = await axios.post("https://fcm.googleapis.com/fcm/send",{...data},{
            headers: {
              "Content-Type": "application/json",
              "Authorization": "key=AAAApcOKBAM:APA91bGGRSk9rDbs6mGdNlHICXGLfObzdulJ7lbwtzF6jwOnVKfx23GmMO3sfuvI2KNnnmsGdXjgShv7ZhHM8I4jaLmS0ljkZiQmE6UfDe-MbvEmTYvnh7IfnoqVrQh6h7GOQufJYAs-",
            },
          });
        } catch (error) {
          console.log(error);
        }
      };
    

    const UploadFunction = async () => {
        
        if ( image1 === "" || image2 === "" || studioName === "" || jobType === "" ||  desc === "" || productionDetail === "" || locationSelected === "" || numberSelected === "") {
            
            if(studioName === ""){
                alert("Please enter studio name");
                return;
            }
            if(jobType === ""){
                alert("Please enter job type");
                return;
            }
            if(desc === ""){
                alert("Please enter description");
                return;
            }
            if(productionDetail === ""){
                alert("Please enter production detail");
                return;
            }
            if(locationSelected === ""){
                alert("Please enter location");
                return;
            }
            if(numberSelected === ""){
                alert("Please enter contact number");
                return;
            }
            if(image1 === "" || image1 === null || image1 === undefined){
                alert("Please upload image 1");
                return;
            }
            if(image2 === "" || image2 === null || image2 === undefined){
                alert("Please upload image 2");
                return;
            }
            return;
        }

        if(!dateSelected && !lastDateSelected){
            return alert("Please Select either audition date or last date to post a job");
        }  
        
        // if(dateSelected!=null && lastDateSelected!=null){
        // if(dateSelected < lastDateSelected){
        //     return alert("Audition date should be greater than last date to post a job");
        // }
        // }

        const userIID = JSON.parse(localStorage.getItem("userDataNew"))._id;
        setOpen(true);
        let url1 = "";
        let url2 = "";
        let images = [];
        // const filename = image1.split("\\")[image1.split("\\").length - 1];
        // const filename1 = image2.split("\\")[image2.split("\\").length - 1];
        // console.log(filename);
        // console.log(filename1);
            try {
            if (image1 !==null && image1 !== undefined && image1 !== "") {
                const datee = new Date().toISOString().split("T")[0];
                const storageRef = ref(storage, `images/${userIID}/${userIID}_${datee}_${image1.name}`);
                const uploadTask = uploadBytesResumable(storageRef, image1);
                await new Promise((resolve, reject) => {
                    uploadTask.on(
                        "state_changed",
                        (snapshot) => {
                          const progress =
                            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                          console.log(`Upload is ${progress}% done`);
                        },
                        (error) => {
                          console.log(error);
                        },
                        () => {
                          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            console.log("File available at", downloadURL);
                            url1 = downloadURL; 
                            images.push(url1);   
                            resolve();          
                          });
                        }
                      );
                })
              }
              if (image2 !==null && image2 !== undefined && image2 !== "") {
                const datee = new Date().toISOString().split("T")[0];
                const storageRef = ref(storage, `images/${userIID}/${userIID}_${datee}_${image2.name}`);
                const uploadTask = uploadBytesResumable(storageRef, image2);
                await new Promise((resolve, reject) => {
                    uploadTask.on(
                        "state_changed",
                        (snapshot) => {
                          const progress =
                            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                          console.log(`Upload is ${progress}% done`);
                        },
                        (error) => {
                          console.log(error);
                        },
                         () => {
                          getDownloadURL(uploadTask.snapshot.ref).then(async(downloadURL) => {
                            console.log("File available at", downloadURL);
                            url2 = downloadURL;
                            images.push(url2);
                              resolve();
                          });
                        }
                      );
                })
              }

              const response = await axios.post(`${url}/api/postJob`, {
                studioName: studioName,
                jobType: jobType,
                socialMedia: socialLink,
                description: desc,
                productionDetail: productionDetail,
                date: dateSelected,
                lastDate: lastDateSelected,
                location: locationSelected,
                contactNumber: numberSelected,
                keyDetails: keyDetails,
                images: images,

            }, {
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    "x-studio-token": localStorage.getItem("x-studio-token"),
                }
            }
            );

            if(response.status === 200){
                setStatuss(response.data);
                const followers = response.data.followers;
                const title = "New Job";
                const message = `${studioName} has posted a job for ${jobType}.`;
                followers.forEach((follower) => {
                    sendInAppNotification(userIID,follower, message);
                    sendPushNotification(title,userIID,follower, message,jobType);
                });
                alert("Job Posted!")
                setOpen(false);
                navigate("/job");
            }
        } catch (error) {
            console.log(error);
            alert(error.response.data.message || error.response.data.msg);
            setOpen(false);
        }
    }





    const [image1, setImage1] = useState(null);
    const [image2, setImage2] = useState(null);
    const [image1Preview, setImage1Preview] = useState(null);
    const [image2Preview, setImage2Preview] = useState(null);


    const userData = JSON.parse(localStorage.getItem("userData"));
    const clearAllData = async () => {
        setOpen(true);
        try {
		localStorage.clear();
                sessionStorage.clear();
                navigate("/");
		const resp = await axios.post(`${url}/api/studio/logout`, {
                noData: "aa"
            }, {
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    "x-studio-token": localStorage.getItem("x-studio-token"),
                }
            });
            if (resp.status === 200) {
                localStorage.clear();
                sessionStorage.clear();
                setOpen(false);
                navigate("/");
                

            } else {
                setOpen(false);
            }

        } catch (error) {
            console.log(`herere is errorror -> ${error.response}`);
            setOpen(false);
        }

    }
    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className={styles.main_yellow}>
                <div className={styles.left_side_menu}>
                    <div className={styles.studio_title}>
                        <img src={userData.profilePic === "" ? blackImage : userData.profilePic} alt="profile-pic" />
                        <span className={styles.studio_name}>Studio</span>
                    </div>
                    <div className={styles.main_menu}>
                        <Link to="/dashboard" className={styles.dashboard_selected}>
                            <div className={styles.main_menu_content}>
                                <img src={chartIcon} alt="icons" /><span> Dashboard</span>
                            </div>
                        </Link>
                        <Link to="/inbox" className={styles.inbox_selected}>
                            <div className={styles.main_menu_content}>
                                <img src={chatIcon} alt="icons" /><span> Inbox</span>
                            </div>
                        </Link>
                        <Link to="/job" className={styles.jobs_selected}>
                            <div className={styles.main_menu_content}>
                                <img src={workIcon} alt="icons" /><span> Jobs</span>
                            </div>
                        </Link>
                        <Link to="/connection" className={styles.connection_selected}>
                            <div className={styles.main_menu_content}>
                                <img src={users3} alt="icons" /><span> Connections</span>
                            </div>
                        </Link>
                        {/* <Link to="/invite" className={styles.invite_selected}>
                            <div className={styles.main_menu_content}>
                                <img src={addUserIcon} alt="icons" /><span> Invite</span>
                            </div>
                        </Link> */}
                        <Link to="/profile" className={styles.profile_selected}>
                            <div className={styles.main_menu_content}>
                                <img src={profileIcon} alt="icons" /><span> Profile</span>
                            </div>
                        </Link>
                        <Link to="/interview" className={styles.schedule_selected}>
                            <div className={styles.main_menu_content}>
                                <img src={calendarIcon} alt="icons" /><span> Schedule
                                    Interview</span>
                            </div>
                        </Link>
                        <Link to="/manager-page" className={styles.manager_selected}>
                            <div className={styles.main_menu_content}>
                                <img src={addUserIcon} alt="icons" /><span>Manager Section</span>
                            </div>
                        </Link>
                        <Link to='/' className={styles.logout_selected}>
                            <div className={styles.main_menu_content} onClick={clearAllData}>
                                <img src={workIcon} alt="icons" /><span>Logout</span>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
            <div className={styles.main_white}>
                <header>
                    <div className={styles.add_details}>
                        <span>Add Details</span>
                    </div>
                    <div className={styles.head_icons}>
                        {/* <button className={styles.notification_button}><img src={notificationBtn}
                        alt="notification" /></button>
                <button className={styles.setting_button}><img src={settingBtn} alt="setting" /></button> */}
                    </div>
                </header>
                <hr className={styles.hrr} />

                <div className={styles.input_divs}>
                    <div className={styles.section_1}>
                        <div id={styles.section_1_1}>
                            <label>Studio Name</label>
                            <input type="text" placeholder="Enter studio name" value={studioName} onChange={e => setStudioName(e.target.value)} />
                        </div>
                        <div id={styles.section_1_1}>
                            <label>Job Type</label>
                            <select onChange={e => setJobType(e.target.value)} value={jobType} style={{height:"3vw"}}>
                                <option value="Actor">Actor</option>
                                <option value="Model">Model</option>
                                <option value="Singer">Singer</option>
                                <option value="Musician">Musician</option>
                                <option value="Writer">Writer</option>
                                <option value="Dancer">Dancer</option>
                                <option value="Choreographer">Choreographer</option>
                                <option value="Designer">Designer</option>
                                <option value="Other">Other</option>
                </select>
                            {/* <input type="text" placeholder="Enter Job Type" value={jobType} onChange={e => setJobType(e.target.value)} /> */}
                        </div>
                        <div id={styles.section_1_1}>
                            <label>Social Media Link</label>
                            <input type="text" placeholder="Add any social media link" value={socialLink} onChange={e => setSocialLink(e.target.value)} />
                        </div>
                    </div>
                    <div className={styles.section_2}>
                        <div id={styles.section_2_1}>
                            <label>Description</label>
                            <input type="text" placeholder="Add Description" value={desc} onChange={e => setDesc(e.target.value)} />
                            {/* <div><span>*Maximum word limit is 1200-1500</span><span>{wc1}/1500</span></div> */}
                            <span>*Maximum word limit is 1200-1500</span>

                        </div>
                        <div id={styles.section_2_1}>
                            <label>Production Detail</label>
                            <input type="text" placeholder="Add production detail" value={productionDetail} onChange={e => setProductionDetail(e.target.value)} />
                            {/* <div><span>*Maximum word limit is 1200-1500</span></div> */}
                            <span>*Maximum word limit is 1200-1500</span>

                        </div>
                    </div>
                    <div className={styles.section_3}>
                        <div id={styles.section_3_1}>
                            <label>Audition Date</label>
                            <input type="date" placeholder="Enter Date" value={dateSelected} onChange={e => setDateSelected(e.target.value)} min={new Date().toISOString().split("T")[0]} />
                        </div>

                        <div id={styles.section_3_1}>
                            <label>Last  Date to Apply</label>
                            <input type="date" placeholder="Enter Date" value={lastDateSelected} onChange={e => setLastDateSelected(e.target.value)} min={new Date().toISOString().split("T")[0]} />
                        </div>
                        
                        <div id={styles.section_3_1}>
                            <label>Location</label>
                            <input type="text" placeholder="Enter Location" value={locationSelected} onChange={e => setLocationSelected(e.target.value)} />
                        </div>
                        <div id={styles.section_3_1}>
                            <label>Contact no.</label>
                            <input type="number" placeholder="Enter Contact no." value={numberSelected} onChange={e => setNumberSelected(e.target.value)}  />
                        </div>
                    </div>
                    <div className={styles.section_3}>
                        <div id={styles.section_3_1_key}>
                            <label>Key Details</label>
                            <input type="text" placeholder="Enter Key Detail" value={keyDetails} onChange={e => setKeyDetails(e.target.value)} />
                            <span>*Maximum word limit is 200-500.</span>
                        </div>
                    </div>
                </div>
                <hr className={styles.hrr} />

                <div className={styles.upload_section}>
                    <div className={styles.upload_sectionss}>
                        <div className={styles.banner_upload}>
                            <span>Banner/Photos(1)</span>
                            <div className={styles.upload_button} onClick={handelClick} id={styles.upload}>
                                <input onChange={e => {
                                    setImage1(e.target.files[0]);
                                    if (e.target.files[0]) {
                                        setImage1Preview(URL.createObjectURL(e.target.files[0]));
                                    }                                    
                                    }} type="file" ref={inputFile} style={{ display: "none" }} id={styles.files} accept="image/png, image/jpeg" />
                                {image1?<>
                                <img style={{height:'50%',width:'50%'}} src={image1Preview} alt="picture upload" />
                                <span>Click on Image to Change</span>
                                </>
                                :<>
                                <img src={uploadIcon} alt="picture upload" />
                                <span>Upload banner/photo</span>
                                </>}
                            </div>
                        </div>
                        <div className={styles.banner_upload}>
                            <span>Banner/Photos(2)</span>
                            <div className={styles.upload_button} onClick={handelClick1} id={styles.upload}>
                                <input onChange={e => {
                                    setImage2(e.target.files[0]);
                                    if (e.target.files[0]){
                                    setImage2Preview(URL.createObjectURL(e.target.files[0]));
                                    }
                                    }} type="file" ref={inputFile1} style={{ display: "none" }} id={styles.files1} accept="image/png, image/jpeg" />
                                {image2?<>
                                <img style={{height:'50%',width:'50%'}} src={image2Preview} alt="picture upload" />
                                <span>Click on Image to Change</span>
                                </>
                                :<>
                                <img src={uploadIcon} alt="picture upload" />
                                <span>Upload banner/photo</span>
                                </>}
                            </div>
                        </div>
                    </div>
                    <button onClick={UploadFunction} className={styles.upld_btn}>Upload</button>
                </div>
            </div>

        </>
    )
}

export default postJobPage
