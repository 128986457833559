exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "* {\n    margin: 0;\n    padding: 0;\n}\n\n.video_image_bg__13rGy {\n    width: 100%;\n    height: 100%;\n    position: fixed;\n    \n}\n\n.video_buttons__2qDmt {\n    display: flex;\n    align-items: center;\n    /* background-color: red; */\n    margin: 40vh 42vw;\n    justify-content: space-between;\n}\n\n.video_buttons__2qDmt img {\n    width: 70px;\n}\n\n.video_slidebar__wfbGA {\n    width: 90vw;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding:  0 5vw;\n}\n\ninput[type = \"range\"] {\n    width: 100%;\n    \n}\n\n", ""]);

// exports
exports.locals = {
	"image_bg": "video_image_bg__13rGy",
	"buttons": "video_buttons__2qDmt",
	"slidebar": "video_slidebar__wfbGA"
};