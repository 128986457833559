import React, { useState, useEffect } from 'react';
import styles from './css/video.module.css';
import forwardBtn from './images/icons/forward.svg';
import backwardBtn from './images/icons/backward.svg';
import img from "./images/video.svg";

function videoPlay() {

    const [play, setPlay] = useState(false);

    const handelChange = () => {
        setPlay(!play);
    }



    return (
        <>
            <div className={styles.image_bg} style={{ background: `url(${img}) no-repeat center/cover` }}>
                <div className={styles.buttons}>
                    <img src={backwardBtn} alt="backward-icon" />
                    <i onClick={handelChange} className={play ? "fa-regular fa-7x fa-circle-pause" : "fa-regular fa-7x fa-circle-play"} id="playbtn"></i>
                    <img src={forwardBtn} alt="forward-icon" />
                </div>

                <div className={styles.slidebar}>
                    <input type="range" />
                </div>
            </div>
        </>
    )
}

export default videoPlay