import React, { useState, useEffect, useRef } from 'react'
import { Link, useNavigate,useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import axios from 'axios';
import styles from './css/inboxStyle.module.css'
// import './css/inbox-resp.css'

import { db } from './firebaseSetup';
import { collection, doc, getDocs,getDoc, where, query, addDoc, orderBy, updateDoc,onSnapshot,arrayUnion } from 'firebase/firestore';
import { storage } from './firebaseSetup';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { getAuth } from "firebase/auth";
import { url } from '../constData'
import blackImage from './images/blackImage.png'
import CloseIcon from '@mui/icons-material/Close';
import chartIcon from './images/icons/Chart.svg'
import chatIcon from './images/icons/Chat.svg'
import workIcon from './images/icons/Work.svg'
import users3 from './images/icons/users3.svg'
import addUserIcon from './images/icons/Add User.svg'
import profileIcon from './images/icons/Profile.svg'
import calendarIcon from './images/icons/Calendar.svg'
import attach from './images/icons/attach.svg'
import { toast } from 'react-toastify';

function inboxPage() {

    // const location = useLocation();
    // if(location.state !== undefined && location.state !== null) {
    // const locationStateGroupIndex = location.state.groupIndex;
    // const locationStateGroupId = location.state.groupId;
    // }
    
    const auth = getAuth();


    const [fuser, setFuser] = useState(null);
    const [message, setMessage] = useState([]);
    let msgArray = [];
    const [inputValue, setInputValue] = useState('')
    const [chatUser, setChatUser] = useState(null);
    let allUserProfilePic = [];
    const [allGroupData, setAllGroupData] = useState([]);
    const [newUserDetails, setNewUserDetails] = useState([]);
    let aGroupData = [];
    let nUserDetails = [];

    const navigate = useNavigate();

    const inputFile = useRef(null);

    const ddd = useRef();

    const userCollectionRef = collection(db, "users",)
    const groupCollectionRef = collection(db, "groups")

    const [open, setOpen] = useState(false);
    const [showChat, setShowChat] = useState(false);
    const [chatOpen, setChatOpen] = useState(false);
    const [sendImg, setSendImg] = useState(null);
    const [reloadtime, setReloadTime] = useState(0);
    const userData = JSON.parse(localStorage.getItem("userData"));
    const [userGroup, setUserGroup] = useState(null);

  

    const getFuserData = async () => {
        setOpen(true);
        const userDataQuery = query(userCollectionRef, where("uid", "==", userData._id));
        const data = await getDocs(userDataQuery);

        Promise.all(
            data.docs[0].data().groups.map(async (group) => {
                const groupId = group.split("_")[0];
                const groupDataQuery = query(groupCollectionRef, where("groupId", "==", groupId));
                const groupData = await getDocs((groupDataQuery));
                aGroupData.push(groupData.docs[0].data())

            })
        ).then((result) => {
            Promise.all(
                aGroupData.map(async (group) => {
                    const newUserId = group.members[1].split("_")[0];
                    const userQuery = query(userCollectionRef, where("uid", "==", newUserId));
                    const userData = await getDocs(userQuery);
                    nUserDetails.push(userData.docs[0].data());
                })
            ).then((result) => {
                if (data.docs[0].data().groups.length === 0) {
                    setChatOpen(false);
                }
                else {
                    setChatOpen(true)
                }

                setFuser(data.docs[0].data());
                setNewUserDetails(nUserDetails);
                setAllGroupData(aGroupData);
                setOpen(false);
                // if(location.state!==undefined && location.state!==null){
                // if(locationStateGroupIndex !== undefined && locationStateGroupIndex !== null) {
                //     sessionStorage.setItem("gId",locationStateGroupId);
                //     getMessages(locationStateGroupId ,locationStateGroupIndex);
                // }
            
            }).catch((err) => {

                console.log(err.stack);
            });


        }).catch((err) => {
            console.log(`error is ${err}`);
        });




    };

    const sendInAppNotification = async (studioId,userId, message) => {
        console.log(userId, message);
        try {
            const currentDatetime = new Date();
            const formattedDatetime = currentDatetime.toISOString().slice(0, 19).replace("T", " ");
          const userDocRef = doc(db, "users", userId);
          const studioDocRef = doc(db, "users", studioId);
          const profilePic = await getDoc(studioDocRef,"profilePic");
          await updateDoc(userDocRef, {
            notification: arrayUnion(`${message}__${profilePic.data().profilePic}_${formattedDatetime}`),
          }); 
        } catch (error) {
          console.log(error);
        }
      };
    
      const sendPushNotification = async (title,studioId,userId, message) => {
          try {
          const userDocRef = doc(db, "users", userId);
          const studioDocRef = doc(db, "users", studioId);
          const profilePic = await getDoc(studioDocRef,"profilePic");
          const userFcmToken = await getDoc(userDocRef,"fCMToken");
    
          let data = {
            to: userFcmToken.data().fCMToken,
            priority: "high",
            notification: {
              title: title,
              body: message,
              alert: true,
              sound : "default",
            },
            data: {
              type: "chat",
            },
            };
    
          const resp = await axios.post("https://fcm.googleapis.com/fcm/send",{...data},{
            headers: {
              "Content-Type": "application/json",
              "Authorization": "key=AAAApcOKBAM:APA91bGGRSk9rDbs6mGdNlHICXGLfObzdulJ7lbwtzF6jwOnVKfx23GmMO3sfuvI2KNnnmsGdXjgShv7ZhHM8I4jaLmS0ljkZiQmE6UfDe-MbvEmTYvnh7IfnoqVrQh6h7GOQufJYAs-",
            },
          });
        } catch (error) {
          console.log(error);
        }
      };
    

    const getMessages = async (groupId, indexx) => {
        setOpen(true);
        setChatUser(indexx);
        sessionStorage.setItem("gId", groupId);
        setUserGroup(groupId);
        const q = collection(db, "groups", groupId, "messages");
        const qq = query(q, orderBy("time"));
        const msgData = await getDocs(qq);
        Promise.all(
            msgData.docs.map((data) => {
                msgArray.push(data.data());
            })

        ).then((result) => {
            setShowChat(true);
            setOpen(false);
            setMessage(msgArray);


        }).catch((err) => {
            console.log(err);
        });
    }


    useEffect(() => {
        if(sessionStorage.getItem("gId") !== null && sessionStorage.getItem("gId") !== undefined && sessionStorage.getItem("gId") !== "") {
        
        const queryMessage = query(collection(db, "groups", sessionStorage.getItem("gId"), "messages"), orderBy("time"));
        var unsubscribe = onSnapshot(queryMessage, (snapshot) => {
                const msgData = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setMessage(msgData);
            });
            return () => unsubscribe();    
    }
    }, [userGroup])


    const handelSubmit = async () => {
        const d = new Date();
        const da = d.getTime();

        const q = collection(db, "groups", sessionStorage.getItem("gId"), "messages");
        // const gRef = collection(db, "groups", sessionStorage.getItem("gId"));
        
        const text = inputValue.trim();
        setInputValue("")

        if (text.length > 0) {
            msgArray = [];
            const msgRef =  await addDoc(q, { sender: fuser.fullName, type: "text", message: text, time: d.getTime() });
            const meeeesg = await updateDoc(doc(db,"groups",sessionStorage.getItem("gId")),{
                recentMessage: text,
                recentMessageSender: fuser.fullName,
                recentMessageTime: d.getTime().toString(),
            });
            const msgData = await getDocs(query(q, orderBy("time")));
            Promise.all(
                msgData.docs.map((data) => {
                    msgArray.push(data.data());
                })

            ).then((result) => {
                setMessage(msgArray);


            }).catch((err) => {
                console.log(err);
            });

            // title,studioId,userId, message
            const studioId = JSON.parse(localStorage.getItem("userDataNew"))._id;
            console.log(studioId);
            const groupRef = doc(db,"groups",sessionStorage.getItem("gId"));
            const groupData = await getDoc(groupRef,"groupName");
            const userId = groupData.data().groupName.split("_")[0];
            console.log(userId);
            sendInAppNotification(studioId,userId, `${fuser.fullName} has sent you a message`);
            sendPushNotification(`${fuser.fullName.toUpperCase()}`,studioId,userId, text);
        }

        else if (sendImg !== null) {
            const userIID = JSON.parse(localStorage.getItem("userDataNew"))._id;
            const datee = new Date().toISOString().split("T")[0];
            const storageRef = ref(storage, `images/${userIID}/${userIID}_${datee}_${sendImg.name}`);

            const uploadTask = uploadBytesResumable(storageRef, sendImg);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    setSendImg(null);
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                }, (err) => console.log(`here is upload error -> ${err}`),
                () => {
                    getDownloadURL(storageRef).then(async (urll) => {
                        msgArray = [];
                        await addDoc(q, { sender: fuser.fullName, type: "img", message: urll, time: d.getTime() });
                        await updateDoc(doc(db, "groups",q), {
                            recentMessage: text,
                            recentMessageSender: fuser.fullName,
                            recentMessageTime: d.getTime().toString(),
                        });
                        const msgData = await getDocs(query(q, orderBy("time")));
                        Promise.all(
                            msgData.docs.map((data) => {
                                msgArray.push(data.data());
                            })

                        ).then((result) => {
                            setMessage(msgArray);
                            setInputValue("");


                        }).catch((err) => {
                            console.log(err);
                            setSendImg(null);
                        });



                    })
                }
            )
           
            const studioId = JSON.parse(localStorage.getItem("userDataNew"))._id;
           
            const groupRef = doc(db,"groups",sessionStorage.getItem("gId"));
            const groupData = await getDoc(groupRef,"groupName");
            const userId = groupData.data().groupName.split("_")[0];
            sendInAppNotification(studioId,userId, `${fuser.fullName} has sent you a message`);
            sendPushNotification(`${fuser.fullName}`,studioId,userId, "Media File");
        }
        else {
            // console.log("nothing");
        }
    }

    const handelInput = (event) => {
        setInputValue(event.target.value);
    }


    const sendImageChat = (event) => {
        setSendImg(event.target.files[0]);
    }


    // const reloadMessage = message.map((msg) =>
    //     msg.sender !== fuser.fullName ? <div className={styles.chat1}>
    //         <img src={chatUser === null ? blackImage : newUserDetails[chatUser].profilePic.length === 0 ? blackImage : newUserDetails[chatUser].profilePic} alt="face3" />
    //         <div className={styles.yellow_color_msg}>
    //             {
    //                 msg.type === "text" ? <span>{msg.message}</span> : <img className={styles.chatImage} src={msg.message} alt="Photo" />
    //             }

    //         </div>
    //     </div> : <div className={styles.chat2}>

    //         <div className={styles.yellow_color_msg}>
    //             {
    //                 msg.type === "text" ? <span>{msg.message}</span> : <img className={styles.chatImage} src={msg.message} alt="aaa" />
    //             }
    //         </div>
    //         <img src={userData.profilePic!==""? userData.profilePic:blackImage} alt="face" />
    //     </div>
    // )

    useEffect(() => {
        if (showChat === true && message.length > 0) {

            ddd.current.scrollIntoView({ behavior: 'auto' });
        }
    }, [message])


    const handelFileTake = () => {
        inputFile.current.click();
    }







    useEffect(() => {
        if (localStorage.getItem("x-studio-token") !== null && localStorage.getItem("x-studio-token") !== "") {

            getFuserData();




        }
        else {
            // console.log("not equals");
            toast.error("Please Login First");
        }
    }, []);

    const clearAllData = async () => {
	localStorage.clear();
                sessionStorage.clear();
                navigate("/");
    
        setOpen(true);
        try {
            const resp = await axios.post(`${url}/api/studio/logout`, {
                noData: "aa"
            }, {
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    "x-studio-token": localStorage.getItem("x-studio-token"),
                }
            });
            if (resp.status === 200) {
                localStorage.clear();
                sessionStorage.clear();
                setOpen(false);
                navigate("/");

            } else {
                setOpen(false);
            }

        } catch (error) {
            console.log(`herere is errorror -> ${error.response}`);
            setOpen(false);
        }

    }



    if (fuser === null) {
        return (
            <>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div className={styles.main_yellow}>
                    <div className={styles.left_side_menu}>
                        <div className={styles.studio_title}>
                            <img src={userData.profilePic ? userData.profilePic:blackImage} alt="profile-pic" />
                            <span className={styles.studio_name}>Studio</span>
                        </div>
                        <div className={styles.main_menu}>
                            <Link to="/dashboard" className={styles.dashboard_selected} >
                                <div className={styles.main_menu_content}>
                                    <img src={chartIcon} alt="icons" /><span> Dashboard</span>
                                </div>
                            </Link>
                            <Link to="/inbox" className={styles.inbox_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={chatIcon} alt="icons" /><span> Inbox</span>
                                </div>
                            </Link>
                            <Link to="/job" className={styles.jobs_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={workIcon} alt="icons" /><span> Jobs</span>
                                </div>
                            </Link>
                            <Link to="/connection" className={styles.connection_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={users3} alt="icons" /><span> Connections</span>
                                </div>
                            </Link>
                            {/* <Link to="/invite" className={styles.invite_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={addUserIcon} alt="icons" /><span> Invite</span>
                                </div>
                            </Link> */}
                            <Link to="/profile" className={styles.profile_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={profileIcon} alt="icons" /><span> Profile</span>
                                </div>
                            </Link>
                            <Link to="/interview" className={styles.schedule_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={calendarIcon} alt="icons" /><span> Schedule
                                        Interview</span>
                                </div>
                            </Link>
                            <Link to="/manager-page" className={styles.manager_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={addUserIcon} alt="icons" /><span>Manager Section</span>
                                </div>
                            </Link>
                            <Link to='/' className={styles.logout_selected}>
                                <div className={styles.main_menu_content} onClick={clearAllData}>
                                    <img src={workIcon} alt="icons" /><span>Logout</span>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={styles.main_white}>

                </div>
            </>
        );
    }

    else {

        return (
            <>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div className={styles.main_yellow}>
                    <div className={styles.left_side_menu}>
                        <div className={styles.studio_title}>
                            <img src={userData.profilePic ? userData.profilePic:blackImage} alt="profile-pic" />
                            <span className={styles.studio_name}>Studio</span>
                        </div>
                        <div className={styles.main_menu}>
                            <Link to="/dashboard" className={styles.dashboard_selected} >
                                <div className={styles.main_menu_content}>
                                    <img src={chartIcon} alt="icons" /><span> Dashboard</span>
                                </div>
                            </Link>
                            <Link to="/inbox" className={styles.inbox_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={chatIcon} alt="icons" /><span> Inbox</span>
                                </div>
                            </Link>
                            <Link to="/job" className={styles.jobs_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={workIcon} alt="icons" /><span> Jobs</span>
                                </div>
                            </Link>
                            <Link to="/connection" className={styles.connection_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={users3} alt="icons" /><span> Connections</span>
                                </div>
                            </Link>
                            {/* <Link to="/invite" className={styles.invite_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={addUserIcon} alt="icons" /><span> Invite</span>
                                </div>
                            </Link> */}
                            <Link to="/profile" className={styles.profile_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={profileIcon} alt="icons" /><span> Profile</span>
                                </div>
                            </Link>
                            <Link to="/interview" className={styles.schedule_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={calendarIcon} alt="icons" /><span> Schedule
                                        Interview</span>
                                </div>
                            </Link>
                            <Link to="/manager-page" className={styles.manager_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={addUserIcon} alt="icons" /><span>Manager Section</span>
                                </div>
                            </Link>
                            <Link to='/' className={styles.logout_selected}>
                                <div className={styles.main_menu_content} onClick={clearAllData}>
                                    <img src={workIcon} alt="icons" /><span>Logout</span>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={styles.main_white}>

                    <div style={{ width: "100%", color: "grey", fontSize: "50px", display: chatOpen ? "none" : "flex", justifyContent: "center", alignItems: "center" }}>
                        <span>No Chat found!</span>
                    </div>

                    <div className={styles.main_white_left} style={{ display: chatOpen ? "block" : "none", visibility: showChat ? "visible" : "hidden" }}>

                        <div className={styles.title_section} >
                            <img src={chatUser === null ? blackImage : newUserDetails[chatUser].profilePic.length === 0 ? blackImage : newUserDetails[chatUser].profilePic} alt="profile-pic" />
                            <span>{chatUser === null ? "" : newUserDetails[chatUser].fullName}</span>
                        </div>
                        <div className={styles.chat_section}>

                            <div style={{ width: "100%", height: "88%", overflow: "auto", display: "flex", flexDirection: "column", gap: "3vh" }}>

                                {message.length === 0 ? <></> : message.map((msg) =>
        msg.sender !== fuser.fullName ? <div className={styles.chat1}>
            <img src={chatUser === null ? blackImage : newUserDetails[chatUser].profilePic.length === 0 ? blackImage : newUserDetails[chatUser].profilePic} alt="face3" />
            <div className={styles.yellow_color_msg}>
                {
                    msg.type === "text" ? <span>{msg.message}</span> : <img className={styles.chatImage} src={msg.message} alt="Photo" />
                }

            </div>
        </div> : <div className={styles.chat2}>

            <div className={styles.yellow_color_msg}>
                {
                    msg.type === "text" ? <span>{msg.message}</span> : <img className={styles.chatImage} src={msg.message} alt="image" />
                }
            </div>
            <img src={userData.profilePic!==""? userData.profilePic:blackImage} alt="face" />
        </div>
    )}
                                <div ref={ddd}></div>

                            </div>

                            <div className={styles.showUploadImage} style={{ display: sendImg === null ? "none" : "flex" }}>
                                <CloseIcon className={styles.crossCancel} onClick={e => setSendImg(null)} />
                                <img src={sendImg === null ? "" : URL.createObjectURL(sendImg)} alt="sendingImageUpload" />
                            </div>

                            <div className={styles.chat_input}>
                                <form onSubmit={e => {
                                    e.preventDefault();
                                    handelSubmit();
                                }} style={{ width: "100%", height: "100%", margin: 0, padding: 0 }}>
                                    <input type="text" value={inputValue} onChange={handelInput} onSubmit={handelSubmit} placeholder="Type your message here" />
                                    <img src={attach} alt="attach" onClick={handelFileTake} />
                                    <input type="file" style={{ display: "none" }} ref={inputFile} onChange={e => sendImageChat(e)} />
                                    <span onClick={handelSubmit}>Send</span>

                                </form>

                            </div>
                        </div>
                    </div>
                    <div className={styles.main_white_right} style={{ display: chatOpen ? "flex" : "none" }}>

                        <div className={styles.main_white_right_bottom}>
                            <span style={{ marginBottom: "20px" }}>Chat Users</span>
                            {allGroupData.map((group, i) =>


                                <div className={styles.people_list} onClick={() => getMessages(group.groupId, i)} key={group.groupId}>
                                    <img src={newUserDetails[i].profilePic.length === 0 ? blackImage : newUserDetails[i].profilePic} alt="pictures" />
                                    <span>{group.members[1].split("_")[1]}</span>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default inboxPage
