import React from 'react'
// import './App.css';
import SignupPage from './components/signinPage';
import LoginPage from './components/LoginPage';
import ForgotPassword from './components/forgotPasswordPage';
import DashboardPage from './components/dashboardPage';
import InboxPage from './components/inboxPage';
import JobPage from './components/jobsPage';
import JobAppliedPage from './components/jobsAppliedPage';
import JobAcceptedPage from './components/jobsAcceptedPage';
import JobShortPage from './components/jobsShortlistedPage';
import PostJobPage from './components/postJobPage';
import JobDescPage from './components/jobDesc';
import ConnectionPage from './components/connectionPage';
import InvitePage from './components/invitePage';
import ProfilePage from './components/profilePage';
import InterviewPage from './components/interviewPage';
import EditProfile from './components/EditProfile';
import VideoPlay from './components/videoPlay';
import ManagerPage from './components/managerPage';
import JobAppliedArtist from './components/jobAppliedArtist';
import HelpAndSupportPage from './components/helpAndSupportPage';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import ManagerDashboard from './components/ManagerDashboard';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/inbox" element={<InboxPage />} />
          <Route path="/job" element={<JobPage />} />
          <Route path="/job-applied" element={<JobAppliedPage />} />
          <Route path="/job-accepted" element={<JobAcceptedPage />} />
          <Route path="/job-short" element={<JobShortPage />} />
          <Route path="/post-job" element={<PostJobPage />} />
          <Route path="/job-description" element={<JobDescPage />} />
          <Route path="/connection" element={<ConnectionPage />} />
          <Route path="/invite" element={<InvitePage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/edit-profile" element={<EditProfile />} />
          <Route path="/interview" element={<InterviewPage />} />
          <Route path="/video-play" element={<VideoPlay />} />
          <Route path="/manager-page" element={<ManagerPage />} />
          <Route path="/applied-artists" element={<JobAppliedArtist />} />
          <Route path="/help" element={<HelpAndSupportPage />} />
          <Route path="/managerpanel" element={<ManagerDashboard/>} />
        </Routes>

      </Router>
    </>
  );
}

export default App;
