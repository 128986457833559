import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { url } from '../constData'
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import styles from './css/connections.module.css';

import chartIcon from './images/icons/Chart.svg'
import chatIcon from './images/icons/Chat.svg'
import workIcon from './images/icons/Work.svg'
import users3 from './images/icons/users3.svg'
import addUserIcon from './images/icons/Add User.svg'
import profileIcon from './images/icons/Profile.svg'
import calendarIcon from './images/icons/Calendar.svg'
import notificationBtn from './images/icons/notification.svg'
import settingBtn from './images/icons/setting-icon.svg'
import filterBtn from "./images/icons/filter-icon.svg"
import downArrow from "./images/icons/down-arrow.svg";
import uploadIcon from "./images/Vector.svg";
import f3 from './images/f3.jpg'
import blackImage from './images/blackImage.png'
import f1 from './images/f1.jpg'
import f2 from './images/f2.jpg'
import f4 from './images/f4.jpg'
import f5 from './images/f5.jpg'
import f6 from './images/f6.jpg'
import attach from './images/icons/attach.svg'

import { db } from './firebaseSetup';
import { collection, doc, getDocs, where, query } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './css/toastt.css';
function connectionPage() {

    const userData = JSON.parse(localStorage.getItem("userData"));


    const search = "";

    const navigate = useNavigate();


    const [newUserData, setnewUserData] = useState("");
    const [showNotify, setShowNotify] = useState(false);


    const [errorr, setErrorr] = useState(null);
    const [open, setOpen] = useState(false);
    const [fuser, setFuser] = useState([]);
    const userCollectionRef = collection(db, "users")

    const [filteredList, setFilteredList] = useState(null);

    const SearchChange = (event) => {
        setFilteredList(newUserData.followers.filter((item) => {
            return item.fname.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1;
        }));
    }

    const getFuserData = async () => {
        const userDataQuery = query(userCollectionRef, where("uid", "==", JSON.parse(localStorage.getItem("userDataNew"))._id))
        const data = await getDocs(userDataQuery);
        setFuser(data.docs[0].data());
    };



    async function fetchData() {
        setOpen(true);
        try {
            const response = await axios.get(`${url}/api/showFollowers`, {
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    "x-studio-token": userData.token.toString(),
                }
            });
            // localStorage.setItem("x-studio-token", response.data.token);
            await getFuserData();
            localStorage.setItem("allFollowers", JSON.stringify(response.data));
            setnewUserData(JSON.parse(localStorage.getItem("allFollowers")));
            setOpen(false);


        } catch (error) {
            setErrorr(error);
            toast.error(error);
            setOpen(false);

        }
    }

    useEffect(() => {
        if (localStorage.getItem("x-studio-token") !== null && localStorage.getItem("x-studio-token") !== "") {

            fetchData();


        }
        else {
            toast.warn("Please Login First");
        }
    }, []);

    const clearAllData = async () => {
        setOpen(true);
        try {
		localStorage.clear();
                sessionStorage.clear();
                navigate("/");
		const resp = await axios.post(`${url}/api/studio/logout`, {
                noData: "aa"
            }, {
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    "x-studio-token": localStorage.getItem("x-studio-token"),
                }
            });
            if (resp.status === 200) {
                localStorage.clear();
                sessionStorage.clear();
                setOpen(false);
                navigate("/");

            } else {
                setOpen(false);
            }

        } catch (error) {
            toast.error(error.response);
            setOpen(false);
        }

    }

    if (newUserData === "") {
        return (
            <>
                <ToastContainer />
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div className={styles.main_yellow}>
                    <div className={styles.left_side_menu}>
                        <div className={styles.studio_title}>
                            <img src={blackImage} alt="profile-pic" />
                            <span className={styles.studio_name}>Studio</span>
                        </div>
                        <div className={styles.main_menu}>
                            <Link style={{ textDecoration: "none" }} to="/dashboard" className={styles.dashboard_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={chartIcon} alt="icons" /><span> Dashboard</span>
                                </div>
                            </Link>
                            <Link style={{ textDecoration: "none" }} to="/inbox" className={styles.inbox_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={chatIcon} alt="icons" /><span> Inbox</span>
                                </div>
                            </Link>
                            <Link style={{ textDecoration: "none" }} to="/job" className={styles.jobs_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={workIcon} alt="icons" /><span> Jobs</span>
                                </div>
                            </Link>
                            <Link style={{ textDecoration: "none" }} to="/connection" className={styles.connection_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={users3} alt="icons" /><span> Connections</span>
                                </div>
                            </Link>
                            {/* <Link style={{ textDecoration: "none" }} to="/invite" className={styles.invite_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={addUserIcon} alt="icons" /><span> Invite</span>
                                </div>
                            </Link> */}
                            <Link style={{ textDecoration: "none" }} to="/profile" className={styles.profile_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={profileIcon} alt="icons" /><span> Profile</span>
                                </div>
                            </Link>
                            <Link style={{ textDecoration: "none" }} to="/interview" className={styles.schedule_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={calendarIcon} alt="icons" /><span> Schedule
                                        Interview</span>
                                </div>
                            </Link>
                            <Link to="/manager-page" className={styles.manager_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={addUserIcon} alt="icons" /><span>Manager Section</span>
                                </div>
                            </Link>
                            <Link to='/' className={styles.logout_selected}>
                                <div className={styles.main_menu_content} onClick={clearAllData}>
                                    <img src={workIcon} alt="icons" /><span>Logout</span>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={styles.main_white}>
                    <div className={styles.top_div}>
                        <input type="text" placeholder="Search here..." />
                        {/* <button className={styles.notification_button}><img src={notificationBtn} alt="notification" /></button>
                        <button className={styles.setting_button}><img src={settingBtn} alt="setting" /></button> */}
                        {/* <button className={styles.filter_button}><img src={filterBtn} alt="filter" /></button> */}
                    </div>
                    <section className={styles.artist_name}>

                    </section>
                    <section className={styles.follow_section}>
                        <button className={styles.btn}>Followers</button>
                        <button className={styles.btn}>Notification</button>
                    </section>
                </div>
            </>
        );
    }

    else {
        return (
            <>
            <ToastContainer />
                <div className={styles.main_yellow}>
                    <div className={styles.left_side_menu}>
                        <div className={styles.studio_title}>
                            <img src={userData.profilePic === "" ? blackImage : userData.profilePic} alt="profile-pic" />
                            <span className={styles.studio_name}>Studio</span>
                        </div>
                        <div className={styles.main_menu}>
                            <Link style={{ textDecoration: "none" }} to="/dashboard" className={styles.dashboard_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={chartIcon} alt="icons" /><span> Dashboard</span>
                                </div>
                            </Link>
                            <Link style={{ textDecoration: "none" }} to="/inbox" className={styles.inbox_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={chatIcon} alt="icons" /><span> Inbox</span>
                                </div>
                            </Link>
                            <Link style={{ textDecoration: "none" }} to="/job" className={styles.jobs_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={workIcon} alt="icons" /><span> Jobs</span>
                                </div>
                            </Link>
                            <Link style={{ textDecoration: "none" }} to="/connection" className={styles.connection_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={users3} alt="icons" /><span> Connections</span>
                                </div>
                            </Link>
                            {/* <Link style={{ textDecoration: "none" }} to="/invite" className={styles.invite_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={addUserIcon} alt="icons" /><span> Invite</span>
                                </div>
                            </Link> */}
                            <Link style={{ textDecoration: "none" }} to="/profile" className={styles.profile_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={profileIcon} alt="icons" /><span> Profile</span>
                                </div>
                            </Link>
                            <Link style={{ textDecoration: "none" }} to="/interview" className={styles.schedule_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={calendarIcon} alt="icons" /><span> Schedule
                                        Interview</span>
                                </div>
                            </Link>
                            <Link to="/manager-page" className={styles.manager_selected}>
                                <div className={styles.main_menu_content}>
                                    <img src={addUserIcon} alt="icons" /><span>Manager Section</span>
                                </div>
                            </Link>
                            <Link to='/' className={styles.logout_selected}>
                                <div className={styles.main_menu_content} onClick={clearAllData}>
                                    <img src={workIcon} alt="icons" /><span>Logout</span>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={styles.main_white}>
                    <div className={styles.top_div}>
                        <input type="text" placeholder="Search here..." onChange={SearchChange} />
                        {/* <button className={styles.notification_button}><img src={notificationBtn} alt="notification" /></button>
                        <button className={styles.setting_button}><img src={settingBtn} alt="setting" /></button> */}
                        {/* <button className={styles.filter_button}><img src={filterBtn} alt="filter" /></button> */}
                    </div>
                    <div style={{ width: "100%", height: "100%", color: "grey", fontSize: "50px", display: newUserData.followers.length === 0 ? "flex" : "none", justifyContent: "center", alignItems: "center" }}>
                        <span>You don't have any followers yet!</span>
                    </div>
                    <section className={styles.artist_name} style={{ display: newUserData.followers.length === 0 ? "none" : showNotify ? "none" : "flex" }}>
                        {/* <p>Followers</p> */}

                        {
                            filteredList === null ?
                                newUserData.followers.map((data) =>
                                    <div className={styles.artist_details}>
                                        <img src={data.profilePic.length === 0 ? blackImage : data.profilePic} alt="face-icon" />
                                        <span>{data.fname}</span>
                                    </div>
                                ) : filteredList.map((data) =>
                                    <div className={styles.artist_details}>
                                        <img src={data.profilePic.length === 0 ? blackImage : data.profilePic} alt="face-icon" />
                                        <span>{data.fname}</span>
                                    </div>
                                )
                        }

                    </section>
                    <section className={styles.notification_list_area} style={{ display: showNotify ? "block" : "none" }} >

                        {
                            fuser.length !== null ?
                                fuser.notification.map((data) =>
                                
                                    <div className={styles.notify_details}>
                                        <img src={data.split("__")[1] === "" ? blackImage : data.split("__")[1]} alt="face-icon" />
                                        <span>{data.split("_")[0]}</span>
                                    </div>
                                ) : <></>
                        }
                    </section>
                    <section className={styles.follow_section} style={{ display: newUserData.followers.length === 0 ? "none" : "flex" }}>
                        <button className={styles.btn} onClick={() => setShowNotify(false)}>Followers</button>
                        <button className={styles.btn} onClick={() => setShowNotify(true)}>Notification</button>
                    </section>
                </div>
            </>
        )
    }




}

export default connectionPage
